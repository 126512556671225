import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MasterPage from './components/MasterPage';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import ServicesPage from './pages/ServicesPage';
import SignUpPage from './pages/SignUpPage';
import NotFoundPage from './pages/NotFoundPage';
import Layout from './components/Layout';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;