import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import '../myApp.css'; // Import the CSS file

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.main`
  flex: 1;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  padding: 0 1rem;
`;

const LeftColumn = styled(Column)`
  width: 15%;
  background-color: #e0e0e0;
`;

const MiddleColumn = styled(Column)`
  width: 66%;
  background-color: #d0d0d0;
`;

const RightColumn = styled(Column)`
  width: 15%;
  background-color: #e0e0e0;
`;

const Menu = styled.nav`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled(Link)`
  padding: 0.5rem 1rem;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ccc;
  }
`;

function Layout({ children }) {
  return (
    <PageWrapper>
      <Header />
      <MainContent>
        <ContentWrapper>
          <LeftColumn>
            <Menu>
              <MenuItem to="/">Home</MenuItem>
              <MenuItem to="/blog">Blog</MenuItem>
              <MenuItem to="/about">About</MenuItem>
              <MenuItem to="/contact">Contact</MenuItem>
            </Menu>
          </LeftColumn>
          <MiddleColumn>
            {children}
          </MiddleColumn>
          <RightColumn>
            {/* Right column content */}
          </RightColumn>
        </ContentWrapper>
      </MainContent>
      <Footer />
    </PageWrapper>
  );
}

export default Layout;
