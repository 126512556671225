import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.main`
  flex: 1;
  width: 80%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
`;

function MasterPage({ children }) {
  return (
    <AppWrapper>
     <Header />
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <Footer /> 
    </AppWrapper>
  );
}

export default MasterPage;