import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background-color: rgb(9, 47, 93);
  color: white;
  padding: 1rem 0;
  text-align: center;
  width: 100%;
  height: 5vh;
`;

const FooterContent = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 0;
`;

function Footer() {
  return (
    <FooterWrapper>
      <FooterContent>
        <p>&copy; 2023 My Website. All rights reserved.</p>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;
