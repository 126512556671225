import React from 'react';
import { createRoot } from 'react-dom/client';
import './myApp.css';
import App from './App';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
