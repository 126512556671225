import React from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  padding: 1rem 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: rgb(9, 47, 93);
  align-text: center;
  width: 100%;
  height: 20vh;
`;

const HeaderContent = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-text: center;
`;

function Header() {
  return (
    <HeaderWrapper>
      <HeaderContent>
        <h1>Your Website Name</h1>
        <nav>
          {/* Add your navigation items here */}
        </nav>
      </HeaderContent>
    </HeaderWrapper>
  );
}

export default Header;
