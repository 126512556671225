import React from 'react';

function BlogPage() {
  return (
    <div>
      <h1>Our Blog</h1>
      <p>Here you can find our latest blog posts and articles.</p>
    </div>
  );
}

export default BlogPage;
